<template>
  <div>
    <el-dropdown @command="command" v-if="canActOnComment">
      <slot name="actions-trigger">
        <span class="el-dropdown-link more">
          <i class="eden-icon-more"></i>
        </span>
      </slot>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :icon="'eden-icon-edit'" :command="'edit'"
          >Edit comment</el-dropdown-item
        >
        <el-dropdown-item :icon="'eden-icon-delete'" :command="'delete'"
          >Delete comment</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  name: "IssueCommentActions",
  props: {
    comment: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    canActOnComment() {
      const { logged_in_userId } = this.$store.getters.user;
      if (this.allowAccessFor(["admin"])) {
        return true;
      }
      return this.comment.commenter.id === logged_in_userId;
    },
  },
  methods: {
    command(command) {
      this.$emit(`click:${command}`);
    },
  },
};
</script>
